
/* TODO Copiado de MainScreen e TreeScreen. Algo generalizável? */


.StatisticsScreenContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgb(245, 245, 245);

    width: 90%;
    min-height: 100vh;

    /* background-color: crimson; */
}


.StatisticsScreenCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    margin-top: 3em;
    margin-bottom: 3em;
    padding: 1em;

    width: 80%;

    background-color: white;

    /* background-color: cyan; */

    /* offset-x | offset-y | blur-radius | color */
    /* Com o blur-radius, definimos o tamanho da sombra.
    Com o offset-y, a arrastamos para baixo, deixando a parte
    inferior mais prounciada. */
    box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
    border-radius: 0.5em;
}


.statistics-pieChart-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    /* background-color: burlywood; */
}


.align-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;

    margin-bottom: 3em;
}


.legendPieGraph {
    /* background-color: yellow; */
}

.pie-graph {
    width: 48%;

    /* background-color: red; */
}


.pieChart-title {
    margin: 0 0 0.5em 0;
    text-align: center;
    width: 100%;
    font-size: 1.3em;
    font-weight: bold;
}

.statistics-text {
    align-self: center;
    width: 80%;
    margin-bottom: 2em;
}

.bar-graph {
    width: 90%;
}




@media (max-width: 1200px) {
    .StatisticsScreenContainer {
        width: 80%;
        padding-top: 2em;
        padding-bottom: 3em;
    }

    .StatisticsScreenCard {
        width: 92%;
        
        margin: 0;
        box-sizing: border-box;
        /* padding: 1em; */
        /* box-shadow: none; */
    }

    .pie-graph {
        width: 100%;
    }

    .statistics-pieChart-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}


@media (max-width: 500px) {
    .StatisticsScreenContainer {
        width: 100%;
        background-color: white;
    }

    .StatisticsScreenCard {
        width: 96%;
        margin: 0;
        padding: 0;
        box-shadow: none;
    }

    .statistics-pieChart-container {
        flex-direction: column;
        align-items: center;
    }

    .statistics-text {
        width: 90%;
    }

    .bar-graph {
        width: 98%;
    }

    .pie-graph {
        /* background-color: blue; */
        width: 100%;
        margin: 0 0 2em 0;

    }

    .responsivePieGraph {

    }
    
}




.FeedbackTypeButton-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 2em;

    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;

    /* width: 16em; */

    background-color: white;
    border-radius: 1em;

    flex-wrap: wrap;
    width: 40%;
    margin: 1em;
}

.FeedbackTypeButton-Container:hover {
    cursor: pointer;
    background-color: rgba(243, 242, 242, 0.8);
}

.FeedbackTypeButton-Container:active {
    box-shadow: none;
}


.FeedbackTypeButton-Text {
    margin: 0;
    padding: 0;

}


.FeedbackTypeButton-Icon {
    font-size: 1.4em;
    margin: 0;
    padding: 0;
}


.FeedbackTypeButton-Label {
    font-size: 1.1em;
    font-weight: bold;
    text-transform: uppercase;

    margin: 0 0 0 0.2em;
    padding: 0;
}

.FeedbackTypeButton-Dica {
    /* width: 60%; */
    margin: 0;
    padding: 0;

}


@media (max-width: 500px) {
    .FeedbackTypeButton-Container {
        background-color: rgba(241, 241, 241, 0.519);
        font-size: 0.8em;
    }
    
}
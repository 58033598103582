

.FeedbackArea-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.FeedbackArea-BttnsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.FeedbackArea-GoBack {
    margin-top: 2em;
}


@media (max-width: 500px) {
    .FeedbackArea-Container {
        padding-bottom: 2em;
    }

}


.RadioBttn-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: center;
    align-items: center;
    width: 100%;

    /* background-color: red; */

    padding: 0;
    margin: 0 0 0 0;
    /* background-color: whitesmoke; */

    cursor: pointer;
}

.RadioBttn-Icon {
    height: 1.5em;
    margin: 0;
}

.RadioBttn-Title {
    font-size: 0.9em;
    font-weight: bold;

    margin: 0 0 0 0.5em;
    padding: 0;
}


.FeedbackGroup-Container {
    width: 98%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;

    margin: 1em;
    padding: 1em 1em 3em 1em;
    background-color: white;
    border-radius: 1em;
}


.FeedbackGroup-Header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.FeedbackGroup-Title {
    margin: 0;
    padding: 0;
    font-size: 1.5em;
}

.FeedbackGroup-TitleDetail {
    font-size: 0.8em;
    color: grey;
    margin-right: 0.3em;
}

.FeedbackGroup-Tagpath { 
    margin-left: 3em;
    font-size: 0.8em;
    color: grey;
}

.FeedbackGroup-ExpandCollapseIcon {
    width: 1.3em;
    margin-right: 1em;
}

.FeedbackGroup-Header:hover {
    cursor: pointer;
}




.UserFeedbackReview {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: rgb(245, 245, 245);

    padding-top: 2em;

    width: 90%;
    min-height: 100vh;
}

.UserFeedback-Card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    margin-top: 2em;
    margin-left: 4em;
    padding: 2em;
    border: 1px solid gray;

    width: 50%;

    border-radius: 1em;

    background-color: white;

    box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
}

.UserFeedback-Card:hover {
    box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.8);
}

.UserFeedback-Details {
    font-size: 0.9em;

}

.UserFeedback-DetailsIcon {
    margin-right: 0.3em;
}

.UserFeedback-ActualFeedback {
    margin: 0 0 0 2em;
    font-size: 1.2em;
    font-weight: bold;
    color:  #8E0011;
}

.UserFeedback-ActualFeedbackDetail {
    margin: 0 0 0 3em;
    font-size: 0.8em;
    color: gray;

}

.UserFeedback-OslerResponse {
    margin: 2em 0 0 2em;
    font-size: 1.2em;
    font-weight: bold;
    color: rgb(39, 121, 196);
}




@media (max-width: 1200px) {
    .UserFeedback-Card {
        width: 80%;
        margin: 2em 0 0 0;
    }
}


@media (max-width: 500px) {
    .UserFeedbackReview {
        width: 100%;
    }

    .UserFeedback-Card {
        width: 96%;
        margin: 2em 0 0 0;
    }
}

.StepType-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    /* background-color: white; */
    width: 45%;
}

.StepNoTests {
    background-color: transparent !important;
    cursor: default !important;
}


.StepType-IconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;

    margin-right: 0.5em;

    /* background-color: red; */
}

.StepType-Icon {
    width: 3em;
    /* box-sizing: content-box; */

    transition: transform 0.3s ease-in-out; /* Transição suave da escala */
    z-index: 10; /* Garantir que a imagem fique acima de outros elementos */

    @media (max-width: 900px) {
        width: 2em;
    }
}


.StepType-Icon:hover {
    cursor: pointer;
    border-radius: 1em;
    transform: scale(1.3);
}


.StepBttns-Container {
    flex-direction: column;
    justify-content: space-around;
    display: flex;
}



.StepBttn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.2em;
}


.StepBttn-Main {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    width: 7em;

    background-color: rgb(208, 208, 208, 0.6);
    padding: 0.5em;
    border-radius: 1em;

    @media (max-width: 900px) {
        width: 6em;
    }
    
}

.StepBttn-Main:hover {
    cursor: pointer;
    background-color: rgb(208, 208, 208, 0.8);
}


.StepBttn-Icon {
    height: 1.2em;
}


.StepBttnNew {
    margin: 0;
    color: #3d3d3d;
    font-weight: bold;
}

.StepBttnReview {
    margin: 0;
    color: #8E0011;
    font-weight: bold;
}

.StepBttnExpl {
    margin: 0;
    font-size: 0.7em;
}

.StepType-Statistics {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.StepBttnStatistics {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: darkgreen;
    font-family: 'Lora';

    @media (max-width: 900px) {
        font-size: 1.1em;
    }
}



/* Não estamos usando */

.NoAction, .NoAction:hover {
    cursor: default !important;
}



.StepNoAction {
    cursor: default !important;
    background-color: lightgreen;
    padding: 0.3em;
    border-radius: 1em;
}

.StepNoAction:hover {
    background-color: lightgreen !important;
    cursor: default !important;
}

.StepBttnIcon {
    height: 2em;
}

.StepBttnTxt {
    font-size: 0.8em;
    color: purple;
    margin-left: 0.5em;
}

.StepBttnInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    margin-left: 0.5em;
}


@media (max-width: 500px) {
    
    .StepBttnContainer {
        flex-direction: column;

        padding: 1em 1em 1em 1em;
        margin: 0;
        width: 5em;

    }


    .StepBttnIcon {
        align-self: center;
        margin-top: 0.5em;
    }

    .StepBttnInfo {
        margin: 0;
    }

    .StepBttnTxt {
        margin: 0;
    }

    .StepBttnNew {
        margin: 0;
    }
}



.FeedbackReviewScreen-Container {
  display: flex;
  flex-direction: column;
  

  background-color: rgb(241, 241, 241);
  padding: 1em;
}

.FeedbackReviewScreen-Error {
  font-size: 2.5em;
  margin-top: 2em;
  text-align: center;
  font-weight: bold;
}

.FeedbackReviewScreen-ChooseTestType {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 98%;

  margin-top: 2em;
  margin-bottom: 3em;
}

.TestType-Selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30%;

  background-color: white;
  border-radius: 2em;
  padding: 1em;
}

.TestType-Selector img {
  width: 2em;
}

.TestType-Selector p {
  margin: 0;
  font-weight: bold;
  font-size: 1.5em;
}

.TestType-Selector-Unselected {
  cursor: pointer;
}

.TestType-Selector-Selected {
  background-color: lightblue;
}

.FeedbackReviewScreen-Header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 98%;

  margin-top: 2em;
  margin-bottom: 3em;
}

.FeedbackCounter {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;
  padding: 2em;
  border-radius: 1em;

  width: 15%;
}

.SelectedCounter {
  background-color: lightgray;
}

.FeedbackCounter:hover {
  cursor: pointer;
}

.FeedbackCounter-Count {
  font-size: 2em;
  padding: 0;
  margin: 0 0 0.2em 0;
  text-align: center;
  
}

.FeedbackCounter-Type {
  font-weight: bold;

  padding: 0;
  margin: 0;
  text-align: center;
}

.FeedbackCounter-Icon {
  margin: 0;
  padding: 0;
  font-size: 2em;
}

  
  .card {

  }
  
  .resolved-button {
    background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  }
  
  .card.resolved {
    background-color: #f0f0f0; /* leve cinza */
  }
  
  .resolved-button.thanked {
    background-color: [outra cor]; /* escolha uma cor */
  }


.section-title-container {
    text-align: center;

    margin-top: 2em;
    margin-bottom: 0em;

    // Fundamental para o mobile. Não cutuque.
    padding: 0px 0.4em 0px 0.4em;
}

.ST-info {
    color: #757575;
    font-size: 1em;
    margin-bottom: 0;
}

.ST-title {
    margin: 0;
    color: #a01d26;
    font-size: 3em;
    font-weight: bold;

    font-family: 'Lora';
}

.ST-subtitle {
    margin: 0em 0 0 0;
    color: black;
    font-size: 1.5em;
    padding: 0 4em 0 4em;
}




@media (max-width: 1400px) {
    .ST-title {
        font-size: 2.2em;
    }
}

@media (max-width:500px) {
    .ST-title {
        font-size: 2.2em;
    }

    .ST-subtitle {
        padding: 0;
        margin-bottom: 1em;
    }
}

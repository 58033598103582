
html {
  /* font-size: 16x !important; */
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;


  font-size: 16px !important;

  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  caret-color: transparent;

  /* Impede que usuário selecione */
  -webkit-user-select: none;  /* Chrome, Safari, Opera */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* Internet Explorer/Edge */
  user-select: none;          /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  width: 100vw;
  max-width: 100%;

}


/* Fucking Google Chrome tries to change the font on inputs
  in LoginScreen and else. */
:-webkit-autofill::first-line,
:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus,
:-webkit-autofill:active {
  font-family: 'Lato', sans-serif !important;
}

/* Acessibility hell, but I dont like outlines. */
a:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

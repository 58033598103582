.faq-container {
	display: flex;
    flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	width: 100vw;
	max-width: 100vw !important;

	padding: 10% 5% 10% 5%;


}

.question-holder {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	align-self: center;

	flex-wrap: wrap;

	width: 80%;
	padding: 1em 4% 1em 4%;

	// background-color: yellow;
}

.faq-question {
	font-weight: bold;
}

.question-div {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	text-align: justify;
	box-sizing: border-box;
	

	width: 45%;
	height: 10vh;
	margin: 1em;

	// background-color: red;
	
	padding: 1% 5% 1% 5%;
}

.question-div h4 {
	font-size: 1.1em;
	margin-bottom: 0;

	// background-color: yellow;
}

.question-div div > p {
	margin-top: 3px;
}

.question-div h4 + p {
	margin-top: 3px;
}

.question-div p + p {
	margin-top: 25px;
}



// 1100 pixels on top, always
@media (max-width: 1200px) {
	.question-holder {
		justify-content: space-between;
		width: 100%;
		padding: 0;
	}

	.question-div {
		width: 100%;
		height: auto;
		padding: 1% 3% 0% 3%;

	}
}

@media (max-width: 500px) {
  .question-holder {
	  justify-content: center;
  }

  .question-div {	
	  width: 95%;
  }
}


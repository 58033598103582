.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 8% 20% 10% 20%;
}

.contact-data {
  margin-top: 2em;

  text-align: center;
  font-size: 1.2em;
  text-align: left;
}


.rowSpan {
  padding-right: 10px;
}



@media only screen and (max-width: 1400px) {

  .contact-container {
    padding: 10% 10% 10% 10%;
  }

  .contact-data {
    padding: 0 10px 0 35px;
    font-size: 1em;
  }
}




@media only screen and (max-width: 500px) {

  .contact-container {
    padding: 10% 0 10% 0;
  }

  .contact-data {
    padding: 0 10px 0 35px;
    font-size: 1em;
  }
}
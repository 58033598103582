html {
  scroll-behavior: smooth;

  /* Desabilita todo tipo de Zoom */
  touch-action: pan-x pan-y;
  height: 100%;

  /* overscroll-behavior-y: contain; */
}


.body {
  /* Evita scroll down to refresh */
  /* overflow-y: hidden; */
}



.overlay {
  min-height: 100vh;
}

a {
  color: blue;
}

a:visited {
  text-decoration: none; 
  color: blue;
}


/* https://fkhadra.github.io/react-toastify/how-to-style/ */
.Toastify__toast-container {
  margin-bottom: 5em !important;
}

.Toastify__toast--dark {
  background: rgba(0, 0, 0, 0.8) !important;
}

.Toastify__progress-bar--dark {
  background-color: #8E0011 !important;
}


@media (max-width: 500px) {
  .Toastify__toast-container {
    margin-top: 5em !important;
  }
  
}
@import '../../node_modules/bootstrap/scss/bootstrap';


// VITTORIO - Botões no Bottom

.header-container {
    height: 11vh;
}

.header-upper-container {
    height: 6vh;
}

.header-bottom-container {
    height: 5vh;
}


.blue-bg {
    // Bottom buttons
    background-color: blue;


}

.red-bg {
    background-color: red;
}


.green-bg {
    background-color: green;

    width: 100%;
}

.purple-bg {
    // Todo o conteúdo abaixo do header
    background-color: purple;
    padding: 0 !important;
}

.test-content {
    background-color: yellow;
    min-height: 90vh;
}


// FIM

.card {
    background-color: $white;
    border-radius: 1em;

    box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);

    padding: 1.5em;
    margin-top: 2em;
}

.modal-dialog {
    top: 50%;
    transform: translateX(-50%) !important;
}

$font-family: 'Lato', sans-serif !important;


.TestContentMobile {
    box-shadow: none;
    background-color: red;
}


.highlight {
    background-color: yellow;
}
// END 'McqQuestionJSX.js'

// BEGINNING 'McqAnswer.JSX.js'
.mcq-answer-feedback-anulada {
    font-size: 0.9em;
    margin: 0;
    font-weight: bold;
    color: red;
    text-align: center;
}



.mcq-bold {
    font-weight: bold;
}
// END 'McqAnswer.JSX.js'

// BEGINNING 'MoveEndQueueButton.js'
.moveEndQueueBttn {
    font-size: 0.7em !important;
    padding: 0em 1em 0 1em !important;

    background-color: rgb(211, 211, 211) !important;
    color: black !important;
    box-shadow: none !important;
    margin-top: 1em;

    align-self: flex-start !important;
}

.moveEndQueueBttn:hover {
    background-color: rgba(211, 211, 211, 0.9) !important;
}
// END 'MoveEndQueueButton.js'


// BEGINNING 'CustomFilterModal.js'
.modal-dialog {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    top: 25%;
    left: 25%;
    right: 25%;
}

.ModalContent {
    position: absolute;
    background-color: white;
    left: 30%;
    right: 30%;
    border: 1px solid #888;
    border-radius: 1em;
    box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
    border-radius: 0.5em;
}

.ModalTextLabel {
    text-align: left;
}

.ModalTextLabel > p {
    font-size: 1.5em;
    text-align: center;
}

.ModalTextInput {
    margin: auto;
    margin-left: 10%;
    margin-right: 7.5%;
    position: relative;
    left: auto;
    font-size: 0.9em;
}

.ModalTextInput > input {
    width: 100%;
    height: 2.5em;

    font-size: 1.0em;
}

.ModalButtonsDiv {
    position: relative;

    left: 5%;
    
    margin: 2em;
    display: inline-flex;
}

.ModalButton {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.SubmitModalButton {
    width: 9em;
    height: 3em;
    border-radius: 1em;

    cursor: pointer;
    
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    align-self: flex-end;
    background-color: rgb(39, 120, 196);
}

.SubmitModalButton > p {
    color: white;
    font-weight: bold;
    font-size: 1.2em;
}

.SubmitModalButton:hover {
    font-weight: bold;
    background-color: rgba(39, 120, 196, 0.9);
}

.CloseModalButton {
    width: 9em;
    height: 3em;
    border-radius: 1em;

    cursor: pointer;
    
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    align-self: flex-end;
    background-color: slategray;

    margin-right: 1em;
}

.CloseModalButton > p {
    color: white;
    font-size: 1.2em;
}

.CloseModalButton:hover {
    background-color: rgba(112, 128, 144, 0.9);
}
// END 'CustomFilterModal.js'

// BEGINNING 'TextBox.js'
.TextArea {
    /* Não permitir que o usuário redimensione o textarea */
    resize: none;

    box-sizing: border-box;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    border-radius: 0.25em;
}

.Bttn {
    font-size: 1.0em;
    width: 15%;
    height: 3em;
    border-width: 0;
    border-radius: 0.5em;
    color: white;
}

.ResetBttn {
    background-color: gray;
}

.ResetBttn:hover {
    background-color: rgba(128, 128, 128, 0.9);
}

.SubmitBttn {
    background-color: rgb(39, 121, 196);
}

.SubmitBttn:hover {
    background-color: rgba(39, 121, 196, 0.9);
}

.CancelBttn {
    background-color: rgb(142, 0, 17);
}

.CancelBttn:hover {
    background-color: rgba(142, 0, 17, 0.9);
}
// END 'TextBox.js'

// BEGINNING 'McqQuestionJSX.js'
.btn-img {
    height: 1.25em;
    width: 1.25em;
}

.highlightedText {
    background-color: rgba(255, 255, 0, 0.4);
}

#question {
    // Fonte: http://codrate.com/articles/disable-amp-enable-text-selection-on-webpage
    -webkit-user-select: text; 
    -khtml-user-select: text; 
    -moz-user-select: text; 
    -o-user-select: text; 
    user-select: text;
}
// END 'McqQuestionJSX.js'
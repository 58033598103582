
.MaintenanceScreen {
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100vh;

    padding: 0 10% 0 10%;
}

.maintenance-logo {
    width: 10em;
}

.maintenance-title {
    font-size: 2em;
    font-weight: bold;
    margin-top: 1em;
}

.maintenance-text {
    font-size: 1.2em;
    text-align: center;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
}
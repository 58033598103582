

.study-method {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    max-width: 100vw !important;
    padding: 5% 23% 5% 23%;

    /* background-color: yellow; */

}

.destaque {
    /* color: #a01d26; */
    color: #005bb0;
}

.destaque2 {
    color: #a01d26;
}



@media (max-width: 1400px) {
    .study-method {
        padding: 0 5% 0 5%;
    }
}

@media (max-width:500px) {
    .study-method {
        /* margin-top: 3em; */
        padding: 0;
    }
}


.HeaderContainer {
    width: 100vw;

    margin: 0;

    padding: 3% 15% 1% 15%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
}


.HeaderBrandContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.HeaderLogo {
    height: 4em;
    margin-right: 0.5em;
}

.HeaderLogo2 {
    height: 2.3em;
    margin-left: 0.5em;
}


.HeaderBttnsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}


.HeaderBttn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 2em;
    /* padding: 1em 2em 1em 2em; */
    padding: 0.8em 2.5em 0.8em 2.5em;

    background-color: rgb(142, 0, 17);

    /* margin: 0 2em 0 2em; */
}


.HeaderBttn:hover {
    cursor: pointer;
    background-color: rgb(142, 0, 17, 0.85);
}


.HeaderBttnText {
    /* width: 14vw; */
    font-weight: bold;
    margin: 0 2em 0 2em;
}

.HeaderBttnEnter {
    background-color: rgb(142, 0, 17);
}

.HeaderBttnEnter:hover {
    background-color: rgb(142, 0, 17, 0.85);
}

.HeaderBttnBuy {
    background-color: rgba(0, 145, 39);
}

.HeaderBttnBuy:hover {
    background-color: rgba(0, 145, 39, 0.8);
}


.HeaderBttnIcon {
    width: 1.8em;
    margin-right: 0.5em;

    background-color: white;
    padding: 0.2em;
    border-radius: 2em;
}


.HeaderBttn > p {
    margin: 0;
    padding: 0;
    /* font-weight: bold; */
    color: white;
    font-size: 1.1em;
}







@media (max-width: 1200px) {
    .HeaderContainer {
        padding-right: 10%;
        padding-left: 10%;
    }
}


@media (max-width: 500px) {
    .HeaderContainer {
        padding-right: 0.5em;
        padding-left: 0.5em;


    }
}
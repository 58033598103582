

.SelectedTests {
    width: 100%;

    padding: 2em 0 0 2em;
    margin: 0;


    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    background-color: #FBFBFB;
}


.ST-Title-Container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin-bottom: 2em;
}

.ST-Title-Txt {
    font-family: 'Lora';
    font-size: 2em;
    margin: 0;
    padding: 0;
}


.ST-Title-Icon {
    width: 2em;
    margin-left: 1em;
}

.ST-Tab-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 75%;

}


.ST-Tab-Bttns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    border-bottom: solid 1px gray;
    
    border-collapse: collapse;
    /* background-color: blue; */
}



.ST-Tab-Content {
    margin-top: 2em;

    /* background-color: purple; */
    width: 90%;
}



.ST-Selector {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    box-sizing: content-box;

    /* padding: 0.5em; */
    padding: 1em 1.5em 1em 1.5em;


    color: gray;
    cursor: pointer;

    background-color: white;
    /* background-color: #FBFBFB */
}



.ST-Selector-Non-Selected { 
    border-top-left-radius: 1.5em;
    border-top-right-radius: 1.5em;
}


.ST-Selector-Non-Selected:hover { 
    background-color: #f0eeee;
}



.ST-Selector-Selected {

    border: solid 1px gray;
    border-top-left-radius: 1.5em;
    border-top-right-radius: 1.5em;

    border-bottom: solid 1px #FBFBFB;


    /* Para juntar melhor com a margem do div adjacente, dando
    aspecto de continuidade */
    margin-bottom: -1px;

    color: black;

    cursor: auto;
    background-color: #FBFBFB;
}

.ST-Selector-Icon {
    width: 1.5em;
}


.ST-Selector-Text {
    margin: 0 0 0 0.6em;
    padding: 0;
    font-size: 1em;

    /* font-family: 'Lato'; */
    /* font-weight: bold; */
    /* font-weight: 600; */
}


.SavedTestsTheme {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 60%;

    border-radius: 0.4em;
    padding: 1.5em;

    margin-bottom: 2em;

    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.SavedTestsTheme:hover {
    /* background-color: rgba(205, 223, 249, 0.6); */
    box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.8);
}


.SavedTestsTheme p {
    margin: 0;
    padding: 0;
}

.ThemeTitle {
    font-weight: bold;
    font-size: 1.1em;
    color: black;
    /* width: 70%; */
}

.ThemeRight {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
    /* background-color: red; */
}


.ThemeNumberTests {
    color: slategray;
    font-size: 1em;

    margin-right: 1em;
}

.ThemeNumberString {
    margin-right: 2em;
}

.ThemeSelectionBttn {
    width: 1em;
}

.ThemeSelectionBttn:hover {
    cursor: pointer;
}


.SavedTests-GoBack {
    /* color: white;
    padding: 0.5em;
    width: 6em;

    background-color: white;
    border: 1px solid lightblue;

    color: black;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 0.5em; */

    cursor: pointer;

    margin-left: 1em;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
}

.SavedTests-GoBack:hover {
    /* box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.8); */
}

.SavedTests-GoBack p {
    margin: 0;
    padding: 0;
}

.SavedTests-GoBack-Icon {
    width: 3em;
}


@media (max-width:1200px) {
    .ST-Tab-Container {
        width: 98%;
    }

    .ST-Tab-Content {
        width: 100%;
    }
}

@media (max-width:500px) {
    .SelectedTests {
        padding: 1em 0 0 1em;
        min-height: 100vh
    }

    .ST-Tab-Container {
        width: 98%;
    }

    .ST-Tab-Content {
        width: 100%;
    }

    .SavedTestsTheme {
        width: 90%;
    }

    .ThemeTitle {
        font-size: 0.95em;
        max-width: 70%;
    }

    .ThemeRight {
        width: 30%;
    }

    .ThemeNumberString {
        text-align: end;
    }
    

    .ThemeNumberTests {
        font-size: 0.9em;
        /* margin-right: 0em; */
    }

    .SavedTests-GoBack-Icon {
        width: 2em;
    }
}

.HomePageContainer {
	display: flex;	
	flex-direction: column;
	height: 100% !important;
}


.section {
    // Fundamental to mobiles, otherwise will
    // have horizontal scroll.
	max-width: 100vw !important;
}

.section-light-bg {
	background-color: white;
}

.section-dark-bg {
	// background-color:#f2f2f2; // #f7f7f7;

	background-color: #F7F7F7;
}

.section-no-padding {
	padding-bottom: 0px !important;
}



.listItem {
    margin-bottom: 0.8em; 
}



.ErrorScreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;
    background-color: rgb(245, 245, 245); 
}

.ErrorScreen-Content {
    width: 40%;

    background-color: white;
    box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
    border-radius: 0.5em;
    padding: 4em;

    display: flex;
    flex-direction: column;
}

.ErrorScreen-Logo {
    width: 30%;
    align-self: center;

    margin-bottom: 2em;
}

.ErrorScreen-Message {
    width: 33%;
}

.ErrorScreen-Error {
    font-size: 0.8em;
    color: gray;
    margin: 0;
}


.ErrorScreen-Title {
    font-size: 1.5em;
    color: #8E0011;
    font-weight: bold;
    text-align: center;
}



@media (max-width: 1400px) {
    .ErrorScreen-Content {
        width: 80%;
    }
}

@media (max-width: 1000px) {
    .ErrorScreen-Content {
        width: 60%;
    }
}

@media (max-width: 500px) {
    .ErrorScreen {
        background-color: white
    }

    .ErrorScreen-Content {
        width: 100%;
        box-shadow: none;
    }

}



.for-whom-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 100vw !important;
    padding: 0 20% 3% 20%;


    /* height: 120vh; */
}

.for-whom-title {
    font-size: 2.7em;
    color: #8E0011;
    margin: 0;
}

.for-whom-boxes-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding-top: 4em;
    padding-bottom: 1em;
}

.for-whom-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


    width: 30% !important;
    height: 52vh;
    padding: 0 1.2em 1.5em 1.2em;
    box-sizing: border-box;

    border-radius: 1.1em;
    border: solid 1px grey;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.4);
    /* box-shadow: 2px 6px 8px 2px rgba(0, 0, 0, 0.4); */
}


.for-whom-box:hover {
    box-shadow: 2px 10px 14px 2px rgba(0,0,0,0.6);
}


.for-whom-box-title {
    font-size: 1.5em;
    color: black;
    font-weight: bold;
    text-transform: uppercase;

    margin: 1.5em 0 1.4em 0;
}

.form-whom-box-text {
    text-align: left;
    align-self: flex-start;
    margin: 0 0 1em 0;
    font-size: 1.1em;
}



@media (max-width: 1400px) {
    .for-whom-container {
        padding: 0 15% 0 15%;

        margin-bottom: 5em;
    }

    .for-whom-box {
        height: 60vh;
    }
}

@media (max-width:500px) {
    
    .for-whom-container {
        padding: 0;
        justify-content: flex-start;
        height: auto;
    }

    .for-whom-boxes-container {
        flex-direction: column;
        justify-content: flex-start;
    }

    .for-whom-boxes-container {
        padding-top: 2em;
    }

    .for-whom-box {
        width: 70% !important;
        height: auto;
        padding: 0 2em 3em 2em;
        margin-bottom: 2em;
    }
}
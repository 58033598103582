
.ConsultScreen {
    width: 100%;

    padding: 2em 0 0 2em;
    margin: 0;


    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    background-color: #FBFBFB
}

.ConsultScreen-Bttns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 15em;

    /* background-color: red; */
}


.AmbassadorDashboard-Screen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 100vh;

    padding-top: 4em;
}

.AmbassadorDashboard-Table {
    width: 100%;
    padding: 5em;
}

.AmbassadorMonthly-Container {
    background-color: rgb(231, 231, 231);
    padding: 3em;
    width: 100%;
    flex-grow: 1;
}


.KeyMetricsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}


.KeyMetricCard {
    background-color: rgb(231, 231, 231);
    padding: 2em;
    border-radius: 1em;

    justify-content: space-evenly;
}

.KeyMetricCard p {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
}

.AmbassadorDashboard-Select {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}


.ComissionStatus-Container {
    padding: 3em;
}

.ComissionStatus-Title {
    font-size: 1.4em;
    font-weight: bold;
    color: #a01d26;
}

.ComissionStatus-Status {
    font-size: 1.3em;
    font-weight: bold;
    margin: 1em 0 0 1em;
}

.ComissionStatus-Coupons {
    color: gray;
    font-size: 0.9em;
    margin: 0 0 0 5em;
    width: 80%;
}
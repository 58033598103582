

.UserAmbassador-Screen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: rgb(245, 245, 245);

    padding-top: 2em;

    width: 90%;
    min-height: 100vh;
}


.UserAmbassador-Card {
    padding: 2em;
    /* width: 20em; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;


    background-color: white;
    border-radius: 1em;

    width: 90%;

    margin-top: 3em;
    margin-bottom: 2em;

    box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
}

.UserAmbassador-Card:hover {
    box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.8);
}

.UserAmbassador-Subtitle {
    font-size: 1.5em;
    font-weight: bold;
    color: #199419;
}

.UserAmbassador-SubtitleSad {
    color: rgb(199, 10, 10) !important;
}


.UserAmbassador-P {
    margin-top: 2em;
}


.UserAmbassador-P2 {
    margin-bottom: 0em;
}

.UserAmbassador-SignUpError {
    color: red;
    margin: 1em 0 0 0;
}

.UserAmbassador-Detail {
    font-size: 0.9em;
    font-weight: bold;
    color: rgb(76, 76, 76);
}

.UserAmbassador-InputInfo {
    margin-bottom: 1em;
    width: 14em;
    padding: 0.5em;
    border: 1.2px solid gray;
    border-radius: 0.6em;
}

.UserAmbassador-Checkbox {
    width: 3em;
    height: 1.2em;
    margin: 0;
}

.UserAmbassador-CheckboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.UserAmbassador-CheckboxContainer p {
    margin: 0;
}

.UserAmbassador-CouponInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}



.EmbaixadoresScreen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;   

    padding: 3em;

    width: 100%;

    background-color: rgb(250, 250, 250);
    min-height: 100vh;
}


.Embaixadores-Header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.EmbaixadoresScreen-LogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.Embaixadores-Logo {
    height: 8em;
    margin-right: 1em;
}


.Embaixadores-Signature {
    height: 4em;
    margin-left: 1em;
}


.Embaixadores-Title {
    font-size: 2em;
    font-weight: bold;
    color: #164eab;

    margin: 0 0 0 1em;
    padding: 0;
}


.EmbaixadoresScreen-Container {
    margin-top: 2em;
    width: 80%;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 4em;
    border-radius: 1em;
    /* box-shadow: 0 4px 8px 0     rgba(0, 0, 0, 0.2); */
}


.EmbaixadoresScreen-InputContainer {
    font-size: 1.5em;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    align-self: center;
}


.Embaixadores-Input {
    width: 20em;
    margin-bottom: 1em;
}

.Embaixadores-Failed {
    color: red;

}

.Embaixadores-PersonalRegistry {
    font-size: 1.5em;
}


.Embaixadores-MonthContainer {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    /* width: 100%; */
    /* background-color: white; */
    /* border: 1px solid; */


    width: 100%;
}



.Embaixadores-ComissionContainer {
    width: 100%;
}


.Embaixadores-MonthData {
    margin-top: 2em;
    font-size: 1.2em;
    color: black;
    
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background-color: white;
    padding: 2em;

}

.receivePayment-inputs {
    display: flex;
    flex-direction: column;
}

.embaixadores-bttn {
    margin: 0.5em;
}




.Embaixadores-pixAttention {
    font-weight: bold;
    color: red
}

.Embaixadores-pixOk {
    font-weight: bold;
    color: darkgreen;
}

.embaixadores-bttn2 {
    margin-top: 2em;
    margin-left: 5em;
    width: 14em;
}

.embaixadores-attention {
    font-size: 1.3em;
    color: rgb(44, 4, 203);
    font-weight: bold;
}


.invoiceInputContainer {
    display: flex;
    flex-direction: column;

}

.invoicePending {
    color: red;
    font-weight: bold;
}

.invoiceOk {
    color: darkgreen;
    font-weight: bold;
}

.Embaixadores-PriceDetail {
    display: flex;
    flex-direction: column;

    background-color: rgb(250, 250, 250);
    padding: 2em;
    border-radius: 1em;
}

.Embaixadores-List {
    margin: 0;
    padding: 0;
}

.PriceDetailTitle {
    font-size: 1.5em;
    font-weight: bold;
    color: blue;
}


.invoiceInputContainer {
    margin-top: 3em;
}

.receivePayment-finalMsg {
    margin-top: 4em;
    width: 30%;
}


.Embaixadores-LastUpdate {
    text-align: center;
    color: rgb(63, 60, 60);
    margin: 0;
    padding: 0;
    align-self: center;
    margin-bottom: 0.5em;
}


.EmbaixadoresScreen-TitleText {
    font-size: 1.5em;
    color: green;
    font-weight: bold;
}

@media (max-width: 500px) {

    .EmbaixadoresScreen {
        padding: 2em 0 0 0;
    }


    .Embaixadores-Title {
        text-align: center;
        margin: 1em 0 0 0;
        font-size: 2.5em;
    }

    .EmbaixadoresScreen-Container {
        width: 100%;
        padding: 1em;
        font-size: 0.85em;
    }

    .Embaixadores-MonthContainer {
        flex-wrap: wrap;
    }

    .embaixadores-bttn2 {
        margin: 0 0 1em 0;
    }
}
.landing-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    max-width: 100vw !important;
    padding: 8% 15% 0% 15%;

    // background-color: yellow;
}


.landing-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    padding-right: 5em;
    // width: 40vw;
}


.landing-title {
    color: #8E0011;
    margin: 0;
    line-height: 1.2em;
    font-size: 3em;
    font-weight: bold;
    font-family: 'Lora';

    text-align: left;
}


.landing-subtitle {
    color: black;
    padding: 0;
    margin: 1vh 0;
    font-size: 1.2em;
    font-weight: 500;

    text-align: left;
}

.landing-demo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.landing-demo-img {
    max-width: 40vw;
    max-height: 90vh;
}



@media (max-width: 1400px) {
    .landing-container {
        padding: 5% 5% 0 5%;
    }

    .landing-title {
        font-size: 2.2em;
        // margin-bottom: 0em;
    }
}


@media (max-width:500px) {

    .landing-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        padding: 5em 0 5em 0;
        margin: 0;

        min-height:fit-content;

    }
    
    .landing-text-container {
        justify-content: flex-start;
        padding: 3em 1em 0 1em;

        height: auto;
    }

    .landing-title {
        font-size: 2.2em;
        margin-bottom: 0em;
    }

    .landing-subtitle {
        margin-top: 1em;
    }

    .landing-demo {
        width: 100%;
    }

    .landing-demo-img {
        max-width: 100vw;

        margin-top: 2em;
    }
}


.about-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 5% 15vw 5% 15vw;
    max-width: 100vw !important;
}


.about-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* background-color: lightblue; */

    width: 70vw;
    margin-top: 4em;
    margin-bottom: 4em;

    text-align: justify;
}


.about-content-reverse {
    /* flex-direction: row-reverse; */
}

.about-text-container {
    width: 37vw;
    padding-right: 3vw;
}



.about-text-container-smaller {
    width: 30vw;
}


.about-text-container h1 {
    font-family: 'Lora';
    font-weight: bold;
    color: #8E0011;

    margin-bottom: 0.7em;
}


.about-text-container p {
    /* color: red; */
    color: rgb(67, 67, 67);

    margin-bottom: 1.5em;
}

.about-text-container p strong {
    /* color: blue; */
    color: black;
    font-size: 1.3em;

}


.about-image-container {
    width: 33vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}


.about-image-container-bigger {
    width: 40vw;
}

.about-img {
    max-width: 90%;
}

.about-img-bigger {
    max-width: 100%;
}


.about-img-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}



@media (max-width: 1400px) {
    .about-container { 
        /* background-color: blue; */
        padding: 5% 5% 5% 5%;
    }

    .about-content {
        /* background-color: yellow; */
        width: 90vw;
        flex-direction: column-reverse;

        margin-top: 0em;
        margin-bottom: 2em;
    }

    .about-content-reverse {
        flex-direction: column !important;
    }

    .about-text-container {
        width: 100%;
        margin-top: 2em;
        /* background-color: pink; */
    }

    .about-image-container {
        max-width: 60%;
        align-items: center;
        width: auto;

        margin-top: 1em;
        margin-bottom: 3em;
        /* background-color: aliceblue; */
    }
}

@media (max-width:500px) {

    .about-container {
        padding: 0;
        /* background-color: red; */
    }


    .about-content {
        flex-direction: column-reverse;
        width: auto;
        width: 90vw;
        /* background-color: blue; */
    }


    .about-content-reverse {
        flex-direction: column !important;
    }


    .about-text-container {
        width: auto;
        padding: 0;
        margin-top: 0em;
        margin-bottom: 2em;
    }


    .about-image-container {
        width: 100%;
        max-width: 100%;
    }


    .about-image-container-bigger {
        width: 100%;
    }
    

    .about-img {
        max-width: 100%;
    }


    .about-text-container h1 {
        margin-top: 1em;
        margin-bottom: 0.5em;
    }
}

.grid-container {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    max-width: 100%;
    box-sizing: border-box;

    padding-top: 2em;
    padding-bottom: 2em;

    // background-color: blue;

}

.grid-container-invert {
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: center;
}



.icon-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    text-align: justify;

    // background-color: red;

    width: 100%;
    padding: 4%;

    margin-top: 1em;
    margin-bottom: 1em;

    // background-color: green;
}


.icon-container-invert {
    flex-direction: row;

    // width: 70%;
    padding: 2% 0% 2% 0%
}


.icon-image {
    align-self: center;
    max-width: 7em;
    margin-right: 4%;
    // background-color: black;
}

.grid-container-title {
    font-family: 'Lora';
    font-weight: bold;
    font-size: 2.2em;
    margin: 0 0 0.2em 0;
}

.container-text {
    padding-right: 1em;
    box-sizing: border-box;

}

.container-text > h4 {
    // color: #a01d26;
    font-size: 1.2em;
    // text-align: center;
    margin: 0;

}

.container-text > ul, li {
    margin-bottom: 1em;
}



@media (max-width: 1200px) {
    .icon-container-invert {
        width: 75%;
    }
}


@media (max-width:500px) {
    .grid-container {
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    .icon-container {
        width: 94%;

        // background-color: red;
        margin-top: 0em;
        padding-top: 0;
    }

    .icon-container-invert {
        flex-direction: column;
        width: 94%;
    }

    .icon-image {
        margin: 0;
        width: 4em;
        margin: 1.5em 0 1.5em 0;
    }

    .grid-container-title {
        font-size: 1.8em;
    }


    .container-text > h4 {
        text-align: center;
    }

    .container-text > ul {
        padding: 0 0.5em 0 1em;
    }
    

}
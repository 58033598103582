
.NiceButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    
    font-size: 1.25em;
    text-align: center;

    width: 6em;
    height: 0.2em;
    padding: 1.8em 1.5em 1.8em 1.5em;
    border-radius: 10px;

    color: white;

    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.4);
}

.NiceButtonContainer:hover {
    opacity: 0.85;
}

.NiceButtonContainer:active {
    box-shadow: 0px 0px 0px 0px;
}


.knowMore-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100vw;

    padding: 10% 0 5% 0;

    /* height: 10vh; */

    /* Tirar após black */
    /* margin-top: 1em; */
    /* background-color: black; */


}

.RobertaMaluca {
    font-size: 1.8em;
    font-weight: bold;
    color: #a01d26;
    font-family: 'Lora';

    margin-top: 0em;
    color: white !important
}


.KnowMoreBuyButton {
    margin-bottom: 1em;

    font-weight: bold;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    align-self: center;

    font-size: 1.3em;
    
    margin-top: 2em;
    margin-bottom: 2em;
    
    width: 12em;
    height: 1em;
    padding: 35px;
    
    border-radius: 10px;

    background-color: #009127;
    color: white;
    text-align: center;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.4);
}

.KnowMoreBuyButton:hover {
    background-color: rgba(0, 145, 39, 0.8);
}

.KnowMoreBuyButton:active {
    box-shadow: 0px 0px 0px 0px;
}



@media (max-width: 1400px) {

}




@media (max-width:500px) {
    .knowMore-container {
        padding: 0 1em 0 1em;
    }

}
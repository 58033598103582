

.MainScreenContainer {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;

    background-color: rgb(245, 245, 245); 
    width: 100%;
    min-height: 100vh;
}

.MainScreenCardsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    flex-flow: wrap;

    width: 100%;
    margin-top: 3em;
}

.MainScreenGraphContainer {
    margin-top: 6em;
    width: 80%; 
}




/* MOBILE */

@media (max-width: 1200px) {
    .MainScreenContainer {
        width: 80%;
        flex-wrap: wrap;
    }
}

@media (max-width: 500px) {
    .MainScreenContainer {
        width: 100%;
        flex-direction: column;
    }

    .MainScreenCardsContainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 2em;
    }

    .MainScreenGraphContainer {
        width: 98%;
    }

}
/* src/CalcPageStyles.css */
.calc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background-color: #d1dbec;
    padding: 40px;
    border-radius: 8px; /* Borda arredondada para o container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    background-color: #dfdfdf !important;
  }

.calc-container h1 {
  color: #A70008;
}

.calc-container select {
width: 190px;
}
  
  .calc-container form {
    margin-bottom: 20px;
margin: 0px !important;
padding: 20px !important; 
max-width: none !important;
color: #A70008 !important;


}
  
  .calc-container input[type="number"],
  .calc-container input[type="checkbox"] {
    margin-right: 10px;
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  
  .calc-container label {
    margin-bottom: 0px  !important;
    display: block;
    padding-left: 0px;
    color: #A70008;
  }

  .calc-titulo {
    margin-left: 0px !important;
    padding-left: 0px;
  }
  
  .calc-container button {
    cursor: pointer;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #A70008;
    color: #fff
  }
  
  .calc-container h2 {
    color: #333;
  }

  .button-voltar-calc {
    background: none !important;
    font-size: 35px;
    margin-left: 10px;
    color: #A70008

}

.calc-titulo {
  color:#A70008;
}
  
  .result-container {
    padding: 20px;
    margin-top: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    box-shadow: none !important;
    min-height: 50px; /* Ajuste conforme necessário para o seu design */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .calc-container {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 20px;
      margin-left: 30px;
      margin-right: 30px;
background: none !important;
      box-shadow: none;
    }
  
    .calc-container button {
      width: 100%;
      padding: 15px 0;
    }
  }
  
  @media (max-width: 729px) {
    .calc-container form {
      width: 60%;
      font-size: 15px;
      margin-left: 20px;
      padding: 0px;

    }

    .calc-container h2, h1 {
        text-align: left;
        padding-left: 10px;
        color: #A70008 

    }

    .calc-container h1 {
      color: #A70008;
      padding-left: 20px;
  }
  
    .calc-container input[type="number"] {
      width: 100%;
      margin-bottom: 10px; /* Espaço extra para dispositivos móveis */
    }
  
    .calc-container label {
      margin-bottom: 15px;
    }

    .calc-container input[type="checkbox"] {
        width: 10%;
        font-size: 10px;
        margin-bottom: 10px; /* Espaço extra para dispositivos móveis */
      }

      .calc-container [type="checkbox"] {
        font-size: 10px !important;
        margin-bottom: 10px; /* Espaço extra para dispositivos móveis */
      }

      .result-container {
        font-size: 18px;
      }

      .nyha-div {
        display: flex;
        flex-direction: row;
      }

      .label-nyha {
        width: 200px;
        margin-left: 10px;
      }
  }
  
  .result-container p {
    font-size: 15px;
  }

  .result-container {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .input-medicos {
    margin-bottom: 20px;
  }

  .input-descanso {
    margin-bottom: 20px;
    align-items: flex-start;
  }

  .calcular-plantao {
    margin-bottom: 20px;
  }

  .botao-plantoes {
    padding: 5px 10px !important ;
    text-align: center;
    align-items: flex-start;
    font-size: 15px;
  }

  .botao-plantoes-remover {
    padding: 5px 10px !important ;
    text-align: center;
    align-items: flex-start;
    font-size: 15px;
    margin-left: 10px;
  }

  .button-voltar-calc {
    color: #A70008 !important;
    text-decoration: none;
    margin-left: 0px;
}

button {
  border-radius: 20px;
  border: none;
  color: #fff4;
}

.calc-container {
  width: 600px;
}

.calc-rendering {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
}

.nyha-div {
  flex-direction: row;
  display: flex;
  gap: 10px;
  margin-bottom: 40px;
}

.calc-container {
  margin-top: 150px;
  min-height: 800px; /* Altura mínima de 800px */
  max-height: 80vh; /* Altura máxima de 80% da visualização */
  overflow-y: auto; /* Rolagem vertical automática */
}

.button-voltar-calc {
padding-bottom: 50px;
}

.form-gad7 {
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding-left: 0px !important;
}

.form-nihss {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .form-gad7 {
    gap: 20px;
    display: flex;
    flex-direction: column;
    padding-left: 0px !important;
    width: 300px !important;
  }

  .calc-page {
    width: 300px !important;
    margin-top: 40px;
  }

  .calc-container {
    width: 300px;
    height: fit-content;
    overflow-y: visible;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 0px;
  }

  .nyha-check {
    width: 20px;
    font-size: 20px;
  }

  .result-container {
    margin-top: 10px;
  }

  .form-nihss {
    overflow-y: scroll;
  }

  .calc-container-nihss {
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 20px;
  }

  .div-nihss {
    display: flex;
    flex-direction: column;
  }

  .calc-rendering {
    height: fit-content;
  }

  .calc-container h1 {
    margin-left: 0px;
    padding-left: 0px;
  }

  .botao-plantoes-remover {
    margin-left: 0px;
    width: 40% !important;
  }

  .botao-plantoes {
    margin-left: 0px;
    width: 40% !important;
  }
}
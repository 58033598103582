

.buy2-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 100vw !important;
    padding: 10% 15% 10% 15%;

}

.buy2-blackfriday {
    background-color: black !important;
}

.buy-options-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

    margin-top: 2em;
    margin-bottom: 5em;
    width: 100%;
}


.buy-option {
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

    /* background-color:     yellow; */

    border: solid 1px grey;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.4);
    border-radius: 1em;

    padding: 1.5em;
    box-sizing: border-box;

    width: 30%;
    height: 40vh;
}

.buy-option:hover {
    box-shadow: 2px 10px 14px 2px rgba(0,0,0,0.6);
}



.buy-options-content-container {
    display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

    /* background-color: green; */
    height: 100%;
}

.buy-option-price-container {
    /* background-color: yellow; */
    height: 35%;
    width: 100%;

}



.buy-option-title {
    color: rgb(82, 82, 82);
    text-align: left;
    font-size: 1.1em;
    margin: 2em 0 0 0;
    text-transform: uppercase;
}

.buy-price {
    margin: 0;
}

.buy-option-price {
    font-size: 2.2em;
    color: rgb(31, 97, 157);
    font-weight: bold;
}


.buy-option-month {
    font-size: 1.2em;
    color: rgb(31, 97, 157);
}


.buy-discount {
    color: green;
    font-size: 1.5em;
    font-weight: bold;
    /* align-self: flex-end; */

    margin: 0 0 1.2em 0;
}

.buy-info {
    color: black;
    margin: 0 0 1em 0;
    text-align: left;
}

.custom-checkout-home {
    margin-top: 8em;
    margin-bottom: 1em;
}

.MainScreenBuyButton {
    margin-bottom: 1em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    align-self: center;

    font-size: 1.3em;
    
    margin-top: 2em;
    margin-bottom: 2em;
    
    /* width: 8em; */
    /* height: 0.4em; */

    padding: 1em;
    
    border-radius: 10px;

    background-color: #009127;
    color: white;
    text-align: center;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.4);
}

.MainScreenBuyButton:hover {
    background-color: rgba(0, 145, 39, 0.8);
}

.MainScreenBuyButton:active {
    box-shadow: 0px 0px 0px 0px;
}


.lastRemarksContainer {
    width: 100%;
    padding: 5em 2% 0 2%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

}

.lastRemark {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    height: 11vh;

    width: 33%;

    margin: 0;

    /* color: white !important; */

    /* background-color: white;
    border-radius: 1em;
    padding: 1em; */
}

.lastRemark-IconContainer {
    height: 40%;
}

.lastRemarkIcon {
    height: 2.5em;
}

.lastRemark-TextContainer {
    height: 60%;

}

.lastRemarkText {
    font-size: 1.15em;
    text-align: center;

    margin: 0;
}



@media (max-width: 1400px) {
    .buy2-container {
        padding: 10% 10% 0 10%;
        /* background-color: red; */
    }

    .buy-option {
        /* height: 50vh; */
        height: auto;
    }

    .lastRemarksContainer {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 0;
        margin: 0;

        /* align-items: flex-start; */
    }

    .lastRemark {
        /* height: 20vh; */

        width: 60%;
        height: auto;
        margin-bottom: 4em;
    }

    .lastRemark-IconContainer {
        height: auto;
        margin-bottom: 1.5em;
    }

    .lastRemark-TextContainer {
        height: auto;
    }

}



@media (max-width:500px) {
    .buy2-container {
        height: auto;
        padding: 0 1em 0 1em;
        justify-content: flex-start;

    }

    .buy-options-container {
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 2em;

    }

    .buy-option {
        width: 100%;
        height: auto;
        margin-bottom: 2em;
    }


    .lastRemarksContainer {
        flex-direction: column;
        padding: 0;
    }

    .lastRemark {
        width: 100%;
        height: auto;
        margin-bottom: 4em;
    }

    .lastRemark-IconContainer {
        height: auto;
    }

    .lastRemark-TextContainer {
        height: auto;
    }
}

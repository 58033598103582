.FeedbackGroupCard-Container {
    display: flex;
    flex-direction: row;
    
    align-items: flex-start;

    margin-top: 2em;
    margin-left: 2em;

    width: 95%;
}


.FeedbackGroupCard-Content {
    width: 60%;

    border: 1px solid black;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0     rgba(0, 0, 0, 0.2);

    height: auto;
}

.FeedbackGroupCard-Buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    
    padding: 0em 0 0em 2em;
    
    width: 40%;
    
    align-self: center;
}


.Input-Confirm-Bttns {
  display: flex;
  flex-direction: row;
}


.FeedbackGroupCard-Top {
  margin: 0;
}


.FeedbackGroupCard-TestID {
  font-size: 1.2em;
  font-weight: bold;
}


.FeedbackGroupCard-Icon {
    font-size: 1.4em;
}


.FeedbackGroupCard-Detail {
  margin: 0 0 0 2em;
  color: gray;
  font-size: 0.9em;
}

.FeedbackGroupCard-Text {
  margin: 1em 0 2em 2em;
}

.FeedbackGroupCard-Type {
    font-size: 1.2em;
    margin-left: 0.5em;
    font-weight: bold;
}

/* .FeedbackGroupCard-Bttn {
    width: 90%;
    height: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.1em;
    bord
    background-color: lightblue;
} */


.FeedbackReviewCard-Check {
  font-size: 3em;
  margin: 0;
}

.FeedbackGroupCard-Bttn {
    width: 40%;
    margin-bottom: 1em;
  }

  .dashboard-bttn {
    text-align: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
    color: white;
  }

  /* Para mostrar que já está ativo e, nessa medida,
  não há porque clicar novamente. */
  /* .dashboard-bttn-active {
    box-shadow: none;
  }

  .dashboard-bttn-active:hover {
    box-shadow: none !important;
  }
  
  .dashboard-bttn-active:focus {
    box-shadow: none !important;
  } */


 
  .button-gray {
    background-color: #6c757d; /* Cinza */
  }
  
  .button-gray:hover, .button-gray:active {
    background-color: #5a6268; /* Cinza mais escuro no hover e ativo */
  }


  .button-gray-active {
    background-color: #363b3f !important;
    box-shadow: none !important;
  }


  .dashboard-bttn:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .dashboard-bttn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 82, 204, 0.5);
  }

  .button-blue {
    background-color: #0052cc; /* Azul */
  }
  
  .button-blue:hover, .button-blue:active {
    background-color: #0041a8; /* Azul mais escuro no hover e ativo */
  }
  
  .button-red {
    background-color: #d9534f; /* Vermelho */
  }
  
  .button-red:hover, .button-red:active {
    background-color: #c9302c; /* Vermelho mais escuro no hover e ativo */
  }
  
  .button-green {
    background-color: #5cb85c; /* Verde */
  }
  
  .button-green:hover, .button-green:active {
    background-color: #4cae4c; /* Verde mais escuro no hover e ativo */
  }
  
  .button-yellow {
    background-color: #daa300; /* Amarelo */
  }
  
  .button-yellow:hover, .button-yellow:active {
    background-color: #c69502; /* Amarelo mais escuro no hover e ativo */
  }
  
  
  .button-purple {
    background-color: #6f42c1; /* Roxo */
  }
  
  .button-purple:hover, .button-purple:active {
    background-color: #5a3791; /* Roxo mais escuro no hover e ativo */
  }
  
  /* Adicione outras cores conforme necessário */
 
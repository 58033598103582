.social-proof-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    max-width: 100vw !important;
    padding: 0 15% 0 15%;

    // Para não dançar quando os depoimentos rodam
    min-height: 45vh;
    height: 45vh !important;
    max-height: 45vh;

    // background-color: #9f2937;
    background-color: #F7F7F7;

}


.social-proof-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    // padding-left: 5em;

    width: 30%;
    text-align: end;
}


.social-proof-title {
    color: #8E0011;
    font-size: 2.5em;
    font-weight: bold;

    font-family: 'Lora';
    margin: 0 0 0.5em 0;
}

.social-proof-text {
    font-size: 1.2em;
}



.OslerCarousel-Container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    padding: 1em;
    width: 70%;

    // height: 70vh;
    min-height: 99%;
    height: 99%;
    max-height: 99%;

    // background-color: green;
}

.OslerCarousel-ImageContainer {
    min-height: 100%;
    height: 100%;
    max-height: 100%;
}



.OslerCarousel-Image {
    max-width: 100%;
    // border: 1px solid;
    // box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);

    // border: solid 1px grey;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    
    // min-height: 99%;
    // height: 99%;
    max-height: 100%;
}



@media (max-width: 1400px) {
    .social-proof-container {
        flex-direction: column-reverse;
        justify-content: flex-start;

        padding: 5em 0 5em 0;
    
        min-height: 90vh;
        height: 90vh !important;
        max-height: 90vh;


        // background-color: yellow;
    }


    .social-proof-text-container {
        width: 100%;
        padding: 5% 20% 3em 20%;

        align-items: flex-start;
        text-align: left;

        // background-color: blue;
    }


    .social-proof-title {
        margin-bottom: 0.5em;
    }


    .OslerCarousel-Container {
        flex-direction: column;
        justify-content: flex-start;

        width: 100%;

        min-height: 70%;
        height: 70%;
        max-height: 70%;

    }
    
}


@media (max-width:500px) {
    .social-proof-container {
        flex-direction: column-reverse;
        justify-content: flex-start;

        padding: 5em 0 5em 0;
    
        max-height: none;
        height: auto !important;
    }

    .social-proof-text-container {
        width: 100%;
        padding: 0em 1em 3em 3em;
        align-items: flex-start;
        text-align: left;
    }

    .social-proof-title {
        margin-bottom: 0.5em;
    }

    .social-proof-text {
        padding-right: 0em;
    }


    .OslerCarousel-Container {
        flex-direction: column;
        justify-content: flex-start;

        width: 100%;

        height: 50vh;
    }

}
